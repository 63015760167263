import axios from "axios";
import { getToken } from "./Localstorage";
import { handleRefreshToken, logout } from "@ntpkunity/controls-ums";
const baseUrl = `${process.env.BASE_URL}/calculate/`;
const benchMarkUrl = `${process.env.BASE_URL}/benchmark/`;

type IQueryParam = {
  Key: string;
  Value: string | number | boolean;
};

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    await handleRefreshToken(error, axios, logout);
  }
);

const post = async (
  url: string,
  requestBody: any,
  isBenchMark: boolean = false,
  queryParams?: IQueryParam[]
) => {
  let response = undefined;

  const token = getToken();

  const newException = (title: string, message: string) => {
    return {
      response: {
        data: {
          Title: title,
          Message: message,
        },
      },
    };
  };

  var _queryParams: any = {};
  queryParams?.map((_qp) => {
    _queryParams[_qp.Key] = _qp.Value;
  });

  await axios
    .post((isBenchMark ? benchMarkUrl : baseUrl) + url, requestBody, {
      params: _queryParams,
      headers: {
        Authorization: "Bearer " + token.access_token,
      },
    })
    .then((res) => {
      if (res.data.status_code != undefined) {
        throw newException("Unauthorized", res.data.message);
      } else {
        response = res.data;
      }
    })
    .catch((ex) => {
      throw ex;
    });
  return response;
};

const get = async (url: string, isBenchMark: boolean = false) => {
  let response = undefined;
  const token = getToken();
  await axios
    .get((isBenchMark ? benchMarkUrl : baseUrl) + url, {
      headers: {
        Authorization: "Bearer " + token.access_token,
      },
    })
    .then((res) => {
      response = res.data;
    })
    .catch((ex) => {
      throw ex;
    });
  return response;
};

const put = async (url: string, isBenchMark: boolean = false) => {
  let response = undefined;
  const token = getToken();
  await axios
    .put((isBenchMark ? benchMarkUrl : baseUrl) + url, {
      headers: {
        Authorization: "Bearer " + token.access_token,
      },
    })
    .then((res) => {
      response = res;
    })
    .catch((ex) => {
      throw ex;
    });
  return response;
};
export { post, get, put };
