import React from "react";
import { Typography, unityTheme } from "@ntpkunity/controls";
import { Table } from "../../controls/Table/Table";

interface IRepaymentPlanDetail {
  rows: [];
}
export const RepaymentPlanDetail: React.FC<IRepaymentPlanDetail> = ({
  rows,
}: IRepaymentPlanDetail) => {
  const columns = [] as any;
  columns.push("Rental Number");
  columns.push("Opening Principal");
  columns.push("Rental Amount");
  columns.push("Rental Principal");
  columns.push("Rental Interest");
  columns.push("Due Date");
  columns.push("Periodic Interest");
  columns.push("Closing Principal");
  if (rows.length > 0) {
    return (
      <div>
        <Typography component="h6" variant="h6" theme={unityTheme}>
          Repayment Plan:
        </Typography>
        <Table columns={columns} rows={rows} />
      </div>
    );
  } else {
    return <div></div>;
  }
};
