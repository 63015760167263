import { styled } from "@mui/material/styles";
import { Box, IBoxProps } from "@ntpkunity/controls";

export const IframeWrap = styled("div")(() => ({
  margin: "0 -40px",
  height: "calc(100vh - 32px)",
}));

export const LoaderWrap = styled(
  Box,
  {}
)<Partial<IBoxProps>>(({ theme }) => ({
  "&.loader-wrap": {
    position: "relative",
    width: "100%",
    height: "100%",
    ".loader": {
      width: 114,
      height: 114,
      position: "absolute",
      left: 0,
      right: 0,
      margin: "auto",
      top: "50%",
      transform: "translateY(-50%)",
      img: {
        maxHeight: 114,
      },
    },
  },
}));
