import React, { useState } from "react";
import { Table } from "../../controls/Table/Table";
import { useSelector } from "react-redux";
import { RootState } from "../../../calc-store/reducers";
import { getReport } from "../../../helpers/ReportingHelper";
import { exportDetail, exportWordPlanUrl } from "../../../common/constants";
import { getToken } from "../../../helpers/Localstorage";
import DateHelper from "../../controls/DateHelper/DateHelper";
import MessageDialog from "../../controls/MessageDialog/MessageDialog";
import { CheckDockSubscribe } from "../../../common/enums";

interface IRepaymentPlanDetail {
  rows: [];
}

export const RepaymentPlanResponse: React.FC<IRepaymentPlanDetail> = ({
  rows,
}: IRepaymentPlanDetail) => {
  const storeState = useSelector((state: RootState) => state.reducerRentalPlan);
  const [enableExport, setEnableExport] = useState(true);
  const [errorDialog, setErrorDialog] = useState({ visible: false, error: "" });
  const GenerateReport = () => {
    const token = getToken();
    var requestObject: any = {
      tenant_id: token.tenant_id,
      template_type: "quote_repayment",
      data_placeholders: {
        financeAmount: storeState.financedAmount,
        currency: "EUR",
        interestRate: storeState.APR,
        Planitems: [],
      },
      generated_doc_content_type: "DOCX",
      is_bookmark: false,
      generate_base64: false,
      tags: {},
    };
    storeState.repaymentPlanApiResponse.repaymentPlan.map((rental: any) => {
      requestObject.data_placeholders.Planitems.push({
        paymentNumber: rental.rentalNumber,
        dueDate: DateHelper(rental.rentalDueDate.split("T")[0]),
        openingPrincipal: rental.openingPrincipal.toLocaleString(),
        paymentAmount: rental.rentalAmount.toLocaleString(),
        principal: rental.rentalPrincipal.toLocaleString(),
        interest: rental.rentalInterest.toLocaleString(),
        periodicInterest: rental.periodicInterest.toLocaleString(),
        closingPrincipal: rental.closingPrincipal.toLocaleString(),
      });
    });
    setEnableExport(false);
    getReport(exportWordPlanUrl, requestObject, "Repayment_plan", true)
      .then(() => {
        setEnableExport(true);
      })
      .catch((ex) => {
        if (ex?.response?.data?.detail == exportDetail)
          setErrorDialog({
            visible: true,
            error: CheckDockSubscribe.NoTemplatefound,
          });
        else {
          setErrorDialog({
            visible: true,
            error: CheckDockSubscribe.PleaseSubscribe,
          });
        }
        setEnableExport(true);
      });
  };
  const columns = [] as any;
  columns.push("Rental #");
  columns.push("Due Date");
  columns.push("Opening Principal");
  columns.push("Rental Amount");
  columns.push("Principal");
  columns.push("Interest");
  columns.push("Periodic Interest");
  columns.push("Closing Principal");
  if (rows.length > 0) {
    return (
      <>
        <MessageDialog
          visible={errorDialog.visible}
          message={errorDialog.error}
          onClose={() => {
            setErrorDialog({
              visible: false,
              error: errorDialog.error,
            });
          }}
        />
        <Table
          columns={columns}
          rows={rows}
          showExport
          fileName={"Repayment Plan"}
          externalExport
          externalExportFunc={GenerateReport}
          enableExport={enableExport}
        />
      </>
    );
  } else {
    return <div></div>;
  }
};
