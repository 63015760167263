import ActionTypes from "../actionTypes";

export type IStepRentalProps = {
  startDate: any;
  rentalMode: string;
  rentalFrequency: string;
  contractTerms: string;
  residualValue: string;
  financedAmount: string;
  APR: string;
  response: [];
  rentalDetail: [];
  repaymentPlan: [];
  stepType: string;
  stepPercentage: string;
  stepFrequency: string;
  rentalCalcMethod: string;
};

export interface IReducerStepRentals {
  stepRentals: IStepRentalProps;
}

const initialState: IReducerStepRentals = {
  stepRentals: {
    startDate: new Date(),
    rentalMode: "",
    rentalFrequency: "",
    contractTerms: "",
    residualValue: "",
    financedAmount: "",
    APR: "",
    response: [],
    repaymentPlan: [],
    rentalDetail: [],
    stepType: "",
    stepPercentage: "",
    stepFrequency: "",
    rentalCalcMethod: "Annuity - 30/360",
  },
};

export function reducersStepRentals(
  currentState = initialState,
  action: any
): IReducerStepRentals {
  switch (action.type) {
    case ActionTypes.SET_SEP_RENTALS: {
      return {
        ...currentState,
        stepRentals: action.payload,
      };
    }
    default:
      return currentState;
  }
}
