import {
  Box,
  Button,
  Grid,
  Icon,
  ISelect,
  IBoxProps,
  unityTheme,
} from "@ntpkunity/controls";
import { useDispatch, useSelector } from "react-redux";
import { setRentalStructures } from "../../../calc-store/actions/actionsRentalPlan";
import { RootState } from "../../../calc-store/reducers";
import { structureRentalTypes } from "../../../common/constants";
import { RentalTypes } from "../../../common/enums";
import IStructureFinancialParam from "../../../interfaces/IStructureFinancialParam";
import { Dropdown, TextBox } from "../../controls";
import { styled } from "@mui/material/styles";
import { SubSectionFieldsWrap } from "./../../../shared/theme/structureRentalStyle";

const GetStructureRentals = (
  rentalStructures: IStructureFinancialParam[],
  deleteFunction,
  valuesChange
) => {
  return rentalStructures.map((structure) => (
    <SubSectionFieldsWrap
      key={structure.RentalId}
      theme={unityTheme}
      className="sub-fields-wrap"
    >
      <Box theme={unityTheme} className="sub-sections-fields">
        <Grid
          theme={unityTheme}
          container
          columnSpacing={3}
          rowSpacing={3}
          mb={3}
        >
          <Grid theme={unityTheme} item xs={12} md={3} lg={3}>
            <TextBox
              id="txtTermFrom"
              label="Term From"
              type="number"
              value={structure.TermFrom}
              onChange={(e) => valuesChange("TermFrom", e, structure.RentalId)}
            />
          </Grid>
          <Grid theme={unityTheme} item xs={12} md={3} lg={3}>
            <TextBox
              id="txtTermTo"
              label="Term To"
              type="number"
              value={structure.TermTo}
              onChange={(e) => valuesChange("TermTo", e, structure.RentalId)}
            />
          </Grid>
          <Grid theme={unityTheme} item xs={12} md={3} lg={3}>
            <TextBox
              id="txtAmount"
              label="Amount00"
              type="text"
              value={structure.Amount}
              masking
              currency
              disabled={
                structure.RentalType == RentalTypes.InterestOnly ||
                structure.RentalType == RentalTypes.Zero
              }
              onChange={(e) => valuesChange("Amount", e, structure.RentalId)}
            />
          </Grid>
          <Grid theme={unityTheme} item xs={12} md={3} lg={3}>
            <Box theme={unityTheme} className="input-with-btn">
              <Dropdown
                label="Type"
                items={structureRentalTypes}
                value={structure.RentalType}
                onChange={(e) =>
                  valuesChange("RentalType", e.target.value, structure.RentalId)
                }
                testid="cbxStructureRentalType"
              />
              <Box theme={unityTheme} className="close-btn">
                <Button
                  theme={unityTheme}
                  key={"btnDelete" + structure.RentalId}
                  onClick={() => deleteFunction(structure.RentalId)}
                  secondary
                  variant="outlined"
                  iconText={<Icon name="CloseBlack" />}
                />
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
      {/* <Box theme={unityTheme} className="sub-section-action">
        <Button
          theme={unityTheme}
          key={"btnDelete" + structure.RentalId}
          onClick={() => deleteFunction(structure.RentalId)}
          secondary
          variant="outlined"
          iconText={<Icon name="CloseBlack" />}
        />
      </Box> */}
    </SubSectionFieldsWrap>
  ));
};

export const StructureRental = () => {
  const storeState = useSelector((state: RootState) => state.reducerRentalPlan);
  const dispatch = useDispatch();
  const addRental = () => {
    var structures = storeState.structureRentalDetail;
    var count = structures.length;
    var _lastTerm = count > 0 ? structures[count - 1].TermTo : 0;
    var newRental: IStructureFinancialParam = {
      RentalId: count + 1,
      RentalType: "Structured",
      Amount: "0.0",
      TermFrom: count > 0 ? ++_lastTerm : 1,
      TermTo: parseFloat(storeState.contractTerms),
    };
    structures.push(newRental);
    dispatch(setRentalStructures(structures));
  };

  const deleteRental = (rentalId: any) => {
    var structures = storeState.structureRentalDetail;
    var indexToDel = structures.findIndex((x) => x.RentalId == rentalId);
    structures.splice(indexToDel, indexToDel > -1 ? 1 : 0);
    dispatch(setRentalStructures(structures));
  };

  const valuesChange = (changeType: string, newValue: any, rentalId: any) => {
    var structures = storeState.structureRentalDetail;
    var indexToEdit = structures.findIndex((x) => x.RentalId == rentalId);
    if (indexToEdit > -1) {
      var value = structures[indexToEdit];
      if (changeType == "TermFrom") value.TermFrom = newValue;
      else if (changeType == "TermTo") value.TermTo = newValue;
      else if (changeType == "Amount") value.Amount = newValue;
      else if (changeType == "RentalType") {
        value.RentalType = newValue;
        if (
          newValue == RentalTypes.InterestOnly ||
          newValue == RentalTypes.Zero
        )
          value.Amount = "0.0";
      }
    }
    dispatch(setRentalStructures(structures));
  };

  if (storeState.rentalStructure == "true") {
    return (
      <Box theme={unityTheme} pb={4} mb={4} className="sub-section">
        {GetStructureRentals(
          storeState.structureRentalDetail,
          deleteRental,
          valuesChange
        )}
        <Grid theme={unityTheme} container mt={4}>
          <Grid theme={unityTheme} item xs={12}>
            <Button
              className="btn-w-100"
              theme={unityTheme}
              secondary
              text="Add More"
              onClick={() => addRental()}
            />
          </Grid>
        </Grid>
      </Box>
    );
  } else {
    return <div></div>;
  }
};
