import { styled } from "@mui/material";
import { Box, Grid, IBoxProps, unityTheme } from "@ntpkunity/controls";
import { useDispatch, useSelector } from "react-redux";
import { setStepRentals } from "../../../calc-store/actions/actionStepRentals";
import { RootState } from "../../../calc-store/reducers";
import {
  StepCalculationMethods,
  rentalFrequencies,
  rentalModes,
  stepRentals,
} from "../../../common/constants";
import { LayoutWithSideNavComponent } from "../../../modules";
import { DatePicker, Dropdown, TextBox } from "../../controls";
import CalculationHeader from "../../controls/CalculationHeader";
import { CalculateRentals } from "./CalculateRentals";
import { StepRentalResponse } from "./StepRentalResponse";

const MainContent = styled(
  Box,
  {}
)<Partial<IBoxProps>>(({ theme }) => ({
  "&.main-content": {
    ".custom-radio-wrap": {
      ".MuiFormGroup-row": {
        ".u-custom-control-label": {
          "&:not(:last-child)": {
            marginRight: 24,
          },
        },
      },
    },
    ".sub-section": {
      borderTop: "1px solid" + theme.palette.grey[100],
      borderBottom: "1px solid" + theme.palette.grey[100],
    },
  },
}));

const StepRental = () => {
  const storeState = useSelector(
    (state: RootState) => state.reducersStepRentals
  );
  const dispatch = useDispatch();
  return (
    <LayoutWithSideNavComponent theme={unityTheme}>
      <MainContent theme={unityTheme} className="main-content">
        <CalculationHeader text={"Step Rental"} />
        <Box theme={unityTheme} mb={2}>
          <Grid theme={unityTheme} container item spacing={3}>
            <Grid theme={unityTheme} item xs={12} sm={4} md={4} lg={4}>
              <Dropdown
                items={StepCalculationMethods}
                label="Rental Calculation"
                onChange={(e) => {
                  dispatch(
                    setStepRentals({
                      ...storeState.stepRentals,
                      rentalCalcMethod: e.target.value,
                    })
                  );
                }}
                helperText=""
                value={storeState.stepRentals.rentalCalcMethod}
                testid="cbxRentalCalculationMethod"
              />
            </Grid>
            <Grid theme={unityTheme} item xs={12} sm={4} md={4} lg={4}>
              <DatePicker
                label="Start Date"
                testId="txtStartDate"
                value={storeState.stepRentals.startDate}
                onChange={(e: any) =>
                  dispatch(
                    setStepRentals({
                      ...storeState.stepRentals,
                      startDate: e,
                    })
                  )
                }
                inputFormat="DD-MMM-YYYY"
              />
            </Grid>
            <Grid theme={unityTheme} item xs={12} sm={4} md={4} lg={4}>
              <Dropdown
                items={rentalModes}
                label={"Payment Mode"}
                value={storeState.stepRentals.rentalMode}
                helperText="If payment to be received at the beginning of the period or end of the period."
                onChange={(e: any) => {
                  dispatch(
                    setStepRentals({
                      ...storeState.stepRentals,
                      rentalMode: e.target.value,
                    })
                  );
                }}
              />
            </Grid>
            <Grid theme={unityTheme} item xs={12} sm={4} md={4} lg={4}>
              <Dropdown
                items={rentalFrequencies}
                label="Payment Frequency"
                value={storeState.stepRentals.rentalFrequency}
                onChange={(e: any) => {
                  dispatch(
                    setStepRentals({
                      ...storeState.stepRentals,
                      rentalFrequency: e.target.value,
                    })
                  );
                }}
                helperText="The frequency of the installment amount to be paid."
                testid="cbxRentalFrequency"
              />
            </Grid>
            <Grid theme={unityTheme} item xs={12} sm={4} md={4} lg={4}>
              <TextBox
                testid="txtTerms"
                id="txtTerms"
                label="Contract Duration in Months"
                type="text"
                helperText="Contract Duration in Months."
                value={storeState.stepRentals.contractTerms}
                masking
                numeric
                scale={0}
                thousandSeparator={false}
                onChange={(e: any) => {
                  dispatch(
                    setStepRentals({
                      ...storeState.stepRentals,
                      contractTerms: e,
                    })
                  );
                }}
              />
            </Grid>
            <Grid theme={unityTheme} item xs={12} sm={4} md={4} lg={4}>
              <TextBox
                testid="txtFinancedAmount"
                id="txtFinancedAmount"
                label="Lending Amount"
                type="text"
                helperText="Final lending amount."
                value={storeState.stepRentals.financedAmount}
                onChange={(e: any) => {
                  dispatch(
                    setStepRentals({
                      ...storeState.stepRentals,
                      financedAmount: e,
                    })
                  );
                }}
                masking
                scale={2}
                currency
              />
            </Grid>
            <Grid theme={unityTheme} item xs={12} sm={4} md={4} lg={4}>
              <TextBox
                testid="txtResidualValue"
                id="txtResidualValue"
                label="Residual Value"
                type="text"
                helperText="Residual Value of the asset paid at the end of the contract."
                value={storeState.stepRentals.residualValue}
                onChange={(e: any) => {
                  dispatch(
                    setStepRentals({
                      ...storeState.stepRentals,
                      residualValue: e,
                    })
                  );
                }}
                masking
                scale={2}
                currency
                optional
              />
            </Grid>
            <Grid theme={unityTheme} item xs={12} sm={4} md={4} lg={4}>
              <TextBox
                testid="txtAPR"
                id="txtAPR"
                label="Interest Rate"
                type="text"
                helperText="Interest Rate."
                value={storeState.stepRentals.APR}
                onChange={(e: any) => {
                  dispatch(
                    setStepRentals({
                      ...storeState.stepRentals,
                      APR: e,
                    })
                  );
                }}
                endAdornment={"%"}
                numeric={true}
                masking={true}
                scale={4}
              />
            </Grid>
            <Grid theme={unityTheme} item xs={12} sm={4} md={4} lg={4}>
              <Dropdown
                items={stepRentals}
                label="Step up / Down"
                value={storeState.stepRentals.stepType}
                helperText="Step up / Down."
                onChange={(e: any) => {
                  dispatch(
                    setStepRentals({
                      ...storeState.stepRentals,
                      stepType: e.target.value,
                    })
                  );
                }}
              />
            </Grid>
            <Grid theme={unityTheme} item xs={12} sm={4} md={4} lg={4}>
              <TextBox
                testid="txtPercentage"
                id="txtPercentage"
                label="Step up / down %"
                type="text"
                helperText="Step up / down %."
                value={storeState.stepRentals.stepPercentage}
                onChange={(e: any) => {
                  dispatch(
                    setStepRentals({
                      ...storeState.stepRentals,
                      stepPercentage: e,
                    })
                  );
                }}
                endAdornment={"%"}
                numeric={true}
                masking={true}
                scale={4}
              />
            </Grid>
            <Grid theme={unityTheme} item xs={12} sm={4} md={4} lg={4}>
              <TextBox
                testid="txtFrequency"
                id="txtFrequency"
                label="Step up / down Frequency"
                type="number"
                helperText="Step up / down Frequency."
                value={storeState.stepRentals.stepFrequency}
                onChange={(e: any) => {
                  dispatch(
                    setStepRentals({
                      ...storeState.stepRentals,
                      stepFrequency: e,
                    })
                  );
                }}
              />
            </Grid>
          </Grid>
        </Box>
        <CalculateRentals />
        <StepRentalResponse />
      </MainContent>
    </LayoutWithSideNavComponent>
  );
};

export default StepRental;
