import { styled } from "@mui/material/styles";
import { Box, IBoxProps } from "@ntpkunity/controls";
import { CustomColors } from "./index";

export const FlexTheme = styled(
  Box,
  {}
)<Partial<IBoxProps>>(({ theme }) => ({
  "&.flex-theme": {
    ".main-content": {
      [theme.breakpoints.down("md")]: {
        paddingTop: 0,
      },
    },
    ".fw-reg": {
      fontWeight: theme.typography.fontWeightRegular,
    },
    ".fw-med": {
      fontWeight: theme.typography.fontWeightMedium,
    },
    ".fw-sb": {
      fontWeight: theme.customVariables.fontWeightSemiBold,
    },
    ".fw-bold": {
      fontWeight: theme.typography.fontWeightBold,
    },
    ".btn": {
      "&.btn-primary": {
        background: CustomColors.gradient,
        borderColor: "transparent",
        border: "none",
        "&:hover": {
          color: theme.palette.common.white,
          background: CustomColors.gradient,
          boxShadow: "0px 8px 16px rgba(0, 0, 0, 0.16)",
          svg: {
            path: {
              stroke: theme.palette.common.white,
            },
          },
        },
        "&:focus-visible": {
          color: theme.palette.common.white,
          background: CustomColors.gradient,
          boxShadow: "0px 8px 16px rgba(0, 0, 0, 0.16)",
          svg: {
            path: {
              stroke: theme.palette.common.white,
            },
          },
        },
        "&:focus": {
          background: CustomColors.gradient,
          color: theme.palette.common.white,
          svg: {
            path: {
              stroke: theme.palette.common.white,
            },
          },
        },
        "&:disabled": {
          backgroundColor: theme.palette.grey[100],
          color: theme.palette.grey[300],
          backgroundImage: "none",
        },
        "&.disabled": {
          backgroundColor: theme.palette.grey[100],
          color: theme.palette.grey[300],
          backgroundImage: "none",
        },
        "&.with-icon": {
          "&:hover": {
            svg: {
              path: {
                stroke: theme.palette.common.white,
              },
            },
          },
          "&:focus-visible": {
            svg: {
              path: {
                stroke: theme.palette.common.white,
              },
            },
          },
          "&:focus": {
            svg: {
              path: {
                stroke: theme.palette.common.white,
              },
            },
          },
        },
      },
      "&.btn-secondary": {
        "&:hover": {
          backgroundColor: theme.palette.grey[900],
          borderColor: theme.palette.grey[900],
          color: theme.palette.common.white,
        },
        "&:focus-visible": {
          backgroundColor: theme.palette.grey[900],
          borderColor: theme.palette.grey[900],
          color: theme.palette.common.white,
        },
        "&:focus": {
          backgroundColor: theme.palette.grey[900],
          borderColor: theme.palette.grey[900],
          color: theme.palette.common.white,
        },
        "&:disabled": {
          borderColor: theme.palette.grey[100],
          backgroundColor: "transparent",
          color: theme.palette.grey[300],
        },
        "&.disabled": {
          borderColor: theme.palette.grey[100],
          backgroundColor: "transparent",
          color: theme.palette.grey[300],
        },
      },
      "&.btn-w-100": {
        [theme.breakpoints.down("sm")]: {
          width: "100%",
        },
      },
    },
    ".u-date-picker": {
      ".u-form-group": {
        ".MuiInputBase-formControl": {
          "&:hover": {
            fieldset: {
              borderColor: "#790082",
            },
          },
          "&.Mui-focused": {
            fieldset: {
              borderColor: "#790082",
            },
          },
        },
      },
      ".MuiPaper-root": {
        ".MuiCalendarPicker-root": {
          ".MuiCalendarPicker-viewTransitionContainer": {
            ".PrivatePickersSlideTransition-root": {
              ".MuiPickersDay-root": {
                "&.Mui-selected": {
                  backgroundColor: "#790082",
                },
              },
            },
          },
          ".PrivatePickersSlideTransition-root": {
            width: "auto",
          },
        },
      },
      [theme.breakpoints.down("sm")]: {
        ".MuiPaper-root": {
          padding: 16,
          borderRadius: theme.shape.borderRadius,
          ".css-epd502": {
            width: "100%",
          },
          ".MuiCalendarPicker-root": {
            width: "100%",
            ">": {
              ".css-1dozdou": {
                minHeight: 24,
                ">": {
                  div: {
                    "+": {
                      div: {
                        ".MuiIconButton-root": {
                          top: 18,
                          "&.MuiIconButton-edgeEnd": {
                            left: 24,
                          },
                          "&.MuiIconButton-edgeStart": {
                            right: 24,
                          },
                        },
                      },
                    },
                  },
                },
              },
            },
          },
        },
      },
    },
    ".tab-list-default": {
      backgroundColor: theme.palette.common.white,
      padding: 3,
      border: "1px solid" + theme.palette.grey[50],
      borderRadius: 16,
      maxWidth: 650,
      margin: "auto auto 24px",
      ".default-varient-btn": {
        backgroundColor: "transparent",
        padding: "13px 30px",
        fontFamily: theme.typography.fontFamily,
        lineHeight: "22px",
        fontWeight: theme.customVariables.fontWeightSemiBold,
        color: theme.palette.grey[900],
        borderRadius: 16,
        "&.Mui-selected": {
          backgroundColor: "transparent",
          fontWeight: theme.customVariables.fontWeightSemiBold,
          boxShadow: "none",
          background: CustomColors.gradient,
          color: theme.palette.common.white,
        },
      },
    },
    [theme.breakpoints.down("md")]: {
      ".styled-tooltip": {
        position: "relative",
        ".MuiTooltip-popper": {
          transform: "none !important",
          top: "60px !important",
          left: "0px !important",
          right: "auto !important",
          '&[data-popper-placement*="right"]': {
            ".MuiTooltip-tooltip": {
              transformOrigin: "left center",
              marginLeft: 0,
            },
            ".MuiTooltip-arrow": {
              left: 10,
              marginLeft: 0,
              height: 10,
              width: 15,
              top: "-13px !important",
              "&:before": {
                transformOrigin: "0 100%",
              },
            },
          },
          '&[data-popper-placement*="left"]': {
            ".MuiTooltip-tooltip": {
              transformOrigin: "left center",
              marginLeft: 0,
            },
            ".MuiTooltip-arrow": {
              left: 10,
              marginLeft: 0,
              height: 10,
              width: 15,
              top: "-13px !important",
              "&:before": {
                transformOrigin: "0 100%",
              },
            },
          },
        },
      },
    },
    [theme.breakpoints.down("md")]: {
      overflowX: "hidden",
    },
  },
}));
