import { Box, Button, Grid, unityTheme, Typography } from "@ntpkunity/controls";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  setComponentRentalDetail,
  setPTStructurePayables,
  setPTStructureReceivables,
  setStructurePTRentalDetails,
} from "../../../calc-store/actions/actionEarlyTermination";
import {
  setExcelData,
  setPlanMultipleData,
} from "../../../calc-store/actions/actionExcelData";
import { setRentalDetailResponse } from "../../../calc-store/actions/actionsRentalCalculation";
import { RootState } from "../../../calc-store/reducers";
import { etCalculationMethods, PurchaseReturn } from "../../../common/enums";
import {
  CreateETQuote,
  CreateETDailyFutureInterest,
  ETNetReceivables,
} from "../../../helpers/ServiceHelper";
import { ValidateEarlyTermination } from "../../../validations/ValidateEarlyTermination";
import DateHelper from "../../controls/DateHelper/DateHelper";
import { IComponentPlan } from "../../controls/ExcelTable/ExcelTable";
import MessageDialog from "../../controls/MessageDialog/MessageDialog";
import { Outputvalue } from "../../controls/OutputValue/Outputvalue";

export const CalculateEarlyTermination = ({ cResponse, setcResponse }) => {
  const storeState = useSelector(
    (state: RootState) => state.reducersEarlyTermination
  );
  const storeState2 = useSelector((state: RootState) => state.reducerExcelData);
  const [isResponse, setIsResponse] = useState(false);
  const dispatch = useDispatch();
  const [calculateEnabled, setCalculateEnabled] = useState(true);
  const [errorDialog, setErrorDialog] = useState({ visible: false, error: "" });
  const [calculateRes, setCalculateRes] = useState(null);
  const [sundryCharges, setsundryCharges] = useState("0");
  const [AdditionalCharges, setAdditionalCharges] = useState("0");
  const [unallocated, setunalocated] = useState("0");
  const [security, setsecurity] = useState("0");
  const [insuraceComp, setinsuraceComp] = useState("0");
  const [registrationComp, setregistrationComp] = useState("0");
  const [purchaseReturn, setPurchaseReturn] = useState("");

  const calculate = () => {
    var receivalble = [];
    var payables = [];
    var rentals = [];
    var etcomp = [];
    var validate = ValidateEarlyTermination(storeState);
    if (validate.visible) {
      setErrorDialog(validate);
    } else {
      dispatch(setRentalDetailResponse([]));
      dispatch(setPTStructureReceivables([]));
      dispatch(setPTStructurePayables([]));
      dispatch(setStructurePTRentalDetails([]));
      if (
        storeState.SundryCharges != null &&
        storeState.SundryCharges != undefined &&
        storeState.SundryCharges != ""
      ) {
        receivalble.push({
          ComponentName: "Sundry Charges",
          Amount: storeState.SundryCharges,
        });
      }
      if (
        storeState.Additionalcharges != null &&
        storeState.Additionalcharges != undefined &&
        storeState.Additionalcharges != ""
      ) {
        receivalble.push({
          ComponentName: "Additional Charges",
          Amount: storeState.Additionalcharges,
        });
      }
      if (
        storeState.SecurityDeposit != null &&
        storeState.SecurityDeposit != undefined &&
        storeState.SecurityDeposit != ""
      ) {
        payables.push({
          ComponentName: "Security Deposit",
          Amount: storeState.SecurityDeposit,
        });
      }
      if (
        storeState.UnallocatedAmount != null &&
        storeState.UnallocatedAmount != undefined &&
        storeState.UnallocatedAmount != ""
      ) {
        payables.push({
          ComponentName: "Unallocated amount",
          Amount: storeState.UnallocatedAmount,
        });
      }
      if (
        storeState2.ExcelMultiple != null &&
        storeState2.ExcelMultiple != undefined &&
        storeState.ETMethod != etCalculationMethods.NetReceivables
      ) {
        const RentalDetail: IComponentPlan = storeState2.ExcelMultiple.find(
          (element: IComponentPlan) => {
            return element.name === "Asset";
          }
        );
        if (RentalDetail != null || RentalDetail != undefined) {
          RentalDetail.rows.map((data: any) => {
            rentals.push({
              RentalNo: data.content[0],
              RentalDueDate: data.content[1],
              OpeningPrincipal: data.content[2],
              NetRental: data.content[3],
              Status: data.content[4],
              RentalInterest: data.content[5],
              ClosingPrincipal: data.content[6],
              Inclusive: [
                {
                  ComponentName: "Insurance",
                  Amount: data.content[7],
                },
                {
                  ComponentName: "Registration",
                  Amount: data.content[8],
                },
              ],
              Overdues: [],
            });
          });
        }
      }
      if (
        (storeState2.ExcelMultiple != null ||
          storeState2.ExcelMultiple != undefined) &&
        storeState.ETMethod == etCalculationMethods.NetReceivables
      ) {
        const RentalDetail: IComponentPlan = storeState2.ExcelMultiple.find(
          (element: IComponentPlan) => {
            return element.name === "Asset";
          }
        );
        if (RentalDetail != null || RentalDetail != undefined) {
          RentalDetail.rows.map((data: any) => {
            rentals.push({
              RentalNo: data.content[0],
              RentalDueDate: data.content[1],
              OpeningPrincipal: data.content[2],
              RentalPrincipal: data.content[3],
              NetRental: data.content[4],
              Status: data.content[5],
              RentalInterest: data.content[6],
              ClosingPrincipal: data.content[7],
              GrossRental: data.content[8],
              PeriodicInterest: data.content[9],
              Inclusive: [
                {
                  ComponentName: "Insurance",
                  Amount: data.content[10],
                },
                {
                  ComponentName: "Registration",
                  Amount: data.content[11],
                },
              ],
            });
          });
        }
        storeState2.ExcelMultiple.map((r: IComponentPlan) => {
          var rr = [];
          r.rows.map((data: any) => {
            rr.push({
              rentalNo: data.content[0],
              rentalDueDate: data.content[1],
              openingPrincipal: data.content[2],
              rentalPrincipal: data.content[3],
              rentalInterest: data.content[4],
              netRental: data.content[5],
              closingPrincipal: data.content[6],
              status: data.content[7],
              periodicInterest: data.content[8],
              grossRental: data.content[9],
              inclusive: [],
              overdues: [],
            });
          });
          if (r.name != "Asset") {
            etcomp.push({
              componentName: r.name,
              componentRentalDetails: rr,
            });
          }
        });
      }
      storeState.Receivables = receivalble;
      storeState.Payables = payables;
      storeState.RentalDetails = rentals;
      storeState.etCompRentalDetails = etcomp;
      switch (storeState.ETMethod) {
        case etCalculationMethods.DailyFutureInterest: {
          setCalculateEnabled(false);
          CreateETDailyFutureInterest(storeState)
            .then((res) => {
              setPurchaseReturn(getBuyOutOREarlyReturnLabel());
              setPlanMultipleData([]);
              setCalculateEnabled(true);
              setCalculateRes(res);
              setIsResponse(true);
            })
            .catch((ex) => {
              setCalculateEnabled(true);
              setErrorDialog({ visible: true, error: ex.response.data });
            });
          return;
        }
        case etCalculationMethods.EffectiveAnnuity: {
          setCalculateEnabled(false);
          CreateETQuote(storeState)
            .then((res) => {
              setPurchaseReturn(getBuyOutOREarlyReturnLabel());
              setPlanMultipleData([]);
              setCalculateEnabled(true);
              setCalculateRes(res);
              setIsResponse(true);
            })
            .catch((ex) => {
              setCalculateEnabled(true);
              setErrorDialog({ visible: true, error: ex.response.data });
            });
          return;
        }
        case etCalculationMethods.NetReceivables: {
          setCalculateEnabled(false);
          ETNetReceivables(storeState)
            .then((res) => {
              setPurchaseReturn(getBuyOutOREarlyReturnLabel());
              setPlanMultipleData([]);
              setCalculateEnabled(true);
              setCalculateRes(res);
              setIsResponse(true);
            })
            .catch((ex) => {
              setCalculateEnabled(true);
              setErrorDialog({ visible: true, error: ex.response.data });
            });
          return;
        }
      }
    }
  };
  const getBuyOutOREarlyReturnLabel = () => {
    if (
      (storeState.ETMethod == etCalculationMethods.DailyFutureInterest ||
        storeState.ETMethod == etCalculationMethods.EffectiveAnnuity ||
        storeState.ETMethod == etCalculationMethods.NetReceivables) &&
      storeState.ETNetReceivableType == null
    ) {
      return "Schedule Principle Outstanding";
    } else if (
      (storeState.ETMethod == etCalculationMethods.DailyFutureInterest ||
        storeState.ETMethod == etCalculationMethods.EffectiveAnnuity) &&
      storeState.ETNetReceivableType != null
    ) {
      return "Schedule Principle Outstanding";
    } else if (storeState.ETNetReceivableType == PurchaseReturn.Purchase) {
      return "Buy out Amount ";
    } else if (storeState.ETNetReceivableType == PurchaseReturn.Return) {
      return "Early Return Amount";
    }
  };
  const EarlyTerminationResponse = () => {
    const storeState = useSelector(
      (state: RootState) => state.reducersEarlyTermination
    );
    // const convertData = (date) => date?.split("T")[0];
    if (isResponse == true) {
      setsundryCharges("0");
      setAdditionalCharges("0");
      setunalocated("0");
      setsecurity("0");
      setinsuraceComp("0");
      setregistrationComp("0");
      const checkSundry = calculateRes.receivables.find((element) => {
        return element.componentName === "Sundry Charges";
      });
      const checkAdditional = calculateRes.receivables.find((element) => {
        return element.componentName === "Additional Charges";
      });
      const checkSecurity = calculateRes.payables.find((element) => {
        return element.componentName === "Security Deposit";
      });
      const checkUnallocated = calculateRes.payables.find((element) => {
        return element.componentName === "Unallocated amount";
      });
      let insuranceComponent = undefined;
      let registrationComponent = undefined;
      if (
        calculateRes.financedComponents != null ||
        calculateRes.financedComponents != undefined
      ) {
        insuranceComponent = calculateRes.financedComponents.find((element) => {
          return element.componentName === "Insurance";
        });
        registrationComponent = calculateRes.financedComponents.find(
          (element) => {
            return element.componentName === "Registration";
          }
        );
      }
      if (checkSundry != undefined || checkSundry != null) {
        setsundryCharges(checkSundry.amount.toString());
      }
      if (checkAdditional != undefined || checkAdditional != null) {
        setAdditionalCharges(checkAdditional.amount);
      }
      if (checkSecurity != undefined || checkSecurity != null) {
        setsecurity(checkSecurity.amount);
      }
      if (checkUnallocated != undefined || checkUnallocated != null) {
        setunalocated(checkUnallocated.amount);
      }
      if (insuranceComponent != undefined || insuranceComponent != null) {
        setinsuraceComp(insuranceComponent.amount);
      }
      if (registrationComponent != undefined || registrationComponent != null) {
        setregistrationComp(registrationComponent.amount);
      }
      return (
        <Box theme={unityTheme} marginTop={10}>
          <Grid theme={unityTheme} container item spacing={3}>
            <Grid theme={unityTheme} item xs={12} sm={4} md={4} lg={4}></Grid>
            <Grid theme={unityTheme}>
              <Typography component={""} theme={unityTheme}>
                Et Client Date
              </Typography>
              <Typography component={""} theme={unityTheme}>
                Et Quote Generation Date
              </Typography>
              <hr />
              <Typography component={""} theme={unityTheme}>
                {purchaseReturn}
              </Typography>
              <Typography component={""} theme={unityTheme}>
                Daily Interest
              </Typography>
              <Typography component={""} theme={unityTheme}>
                Past Due Rental / Overdue Rentals
              </Typography>
              <hr />
              <Typography component={""} theme={unityTheme}>
                Insurance Component
              </Typography>
              <Typography component={""} theme={unityTheme}>
                Registration Component
              </Typography>
              <Typography component={""} theme={unityTheme}>
                Maintenance Fee On Arrear Rental
              </Typography>
              <hr />
              <Typography component={""} theme={unityTheme}>
                Taxes
              </Typography>
              <Typography component={""} theme={unityTheme}>
                Late Payment Penalty
              </Typography>
              <Typography component={""} theme={unityTheme}>
                Sundry Charges
              </Typography>
              <Typography component={""} theme={unityTheme}>
                Additional Charges
              </Typography>
              <Typography component={""} theme={unityTheme}>
                ET Penalty
              </Typography>
              <hr />
              <Typography component={""} theme={unityTheme}>
                Security deposit
              </Typography>
              <Typography component={""} theme={unityTheme}>
                Unallocated Amount
              </Typography>
              <hr />
              <Typography component={""} theme={unityTheme}>
                Gross Receivable Amount
              </Typography>
              <Typography component={""} theme={unityTheme}>
                WHT
              </Typography>
              <Typography component={""} theme={unityTheme}>
                Net Receivable Amount
              </Typography>
            </Grid>
            <Grid theme={unityTheme} textAlign={"end"}>
              <Outputvalue
                value={DateHelper(calculateRes.clientEtDate)}
                formatValue={false}
              />
              <Outputvalue
                value={DateHelper(calculateRes.etQuoteGenerationDate)}
                formatValue={false}
              />
              <hr />
              <Outputvalue
                value={calculateRes.schedulePrincipalOutstanding}
                prefix="$"
                formatValue={true}
              />
              <Outputvalue
                value={calculateRes.dailyInterest}
                prefix="$"
                formatValue={true}
              />
              <Outputvalue
                value={calculateRes.overdueRentalAmount}
                prefix="$"
                formatValue={true}
              />
              <hr />
              <Outputvalue value={insuraceComp} prefix="$" formatValue={true} />
              <Outputvalue
                value={registrationComp}
                prefix="$"
                formatValue={true}
              />
              <Outputvalue value={"0"} prefix="$" formatValue={true} />
              <hr />
              <Outputvalue value={"0"} prefix="$" formatValue={true} />
              <Outputvalue
                value={calculateRes.latePaymentPenalty}
                prefix="$"
                formatValue={true}
              />
              <Outputvalue
                value={sundryCharges}
                prefix="$"
                formatValue={true}
              />
              <Outputvalue
                value={AdditionalCharges}
                prefix="$"
                formatValue={true}
              />
              <Outputvalue
                value={calculateRes.etPenaltyAmount}
                prefix="$"
                formatValue={true}
              />
              <hr />
              <Outputvalue value={security} prefix="$" formatValue={true} />
              <Outputvalue value={unallocated} prefix="$" formatValue={true} />
              <hr />
              <Outputvalue
                value={calculateRes.grossReceivableAmount}
                prefix="$"
                formatValue={true}
              />
              <Outputvalue value={"0"} prefix="$" formatValue={true} />
              <Outputvalue
                value={calculateRes.netReceivableAmount}
                prefix="$"
                formatValue={true}
              />
            </Grid>
          </Grid>
        </Box>
      );
    } else {
      return <div></div>;
    }
  };
  return (
    <>
      <Box theme={unityTheme} mb={3}>
        <MessageDialog
          visible={errorDialog.visible}
          message={errorDialog.error}
          onClose={() => {
            setErrorDialog({
              visible: false,
              error: errorDialog.error,
            });
          }}
        />
        <Button
          className="btn-w-100"
          data-testid="txtCalculate"
          theme={unityTheme}
          onClick={() => {
            calculate();
            dispatch(setComponentRentalDetail([]));
            dispatch(setPlanMultipleData([]));
          }}
          primary
          disabled={!calculateEnabled}
          text="Calculate"
        />
      </Box>
      <EarlyTerminationResponse />
    </>
  );
};
